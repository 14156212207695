/*
 * groupMe Analytics
 * by Kevin Sutyla
 *
 * description: Read the title. You'll figure out what it does.
 *
 */

// APIs
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'; // Import Redux DevTools extension
import multi from 'redux-multi';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// React objects
import App from './App';

// constants
import loginReducer from './reducers/loginReducer';
import tokenReducer from './reducers/tokenReducer';
import viewReducer from './reducers/viewReducer';
import userReducer from './reducers/userReducer';
import queriesReducer from './reducers/queriesReducer';
import settingsReducer from './reducers/settingsReducer';

// Configure Redux-Persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['token', 'queries', 'settings'] // Specify which parts of state you want to persist
};

const rootReducer = combineReducers({
  login: loginReducer,
  token: tokenReducer,
  view: viewReducer,
  user: userReducer,
  queries: queriesReducer,
  settings: settingsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Set up the Redux store with middleware, DevTools, and the persisted reducer
const middleware = applyMiddleware(multi);
const store = createStore(
  persistedReducer,
  composeWithDevTools(middleware) // Apply middleware and integrate with Redux DevTools
);
const persistor = persistStore(store);

const firebaseConfig = {
  apiKey: "AIzaSyC-aCTgkaxMvfMDw3pK1Y-WrMWZx-1Mf1w",
  authDomain: "groupmeshortstories.com",
  databaseURL: "https://groupmeanalytics-default-rtdb.firebaseio.com",
  projectId: "groupmeanalytics",
  storageBucket: "groupmeanalytics.appspot.com",
  messagingSenderId: "1045974698011",
  appId: "1:1045974698011:web:46fdb9a53078d1398990be",
  measurementId: "G-0Y3KNDQVPC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6LeOgzEqAAAAAA2xTCvOr3UiNMFYaBxyXEnoI7NO'),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

// analytics
const analytics = getAnalytics(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('app')
);

export default app;
export { db };
