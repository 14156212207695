/*
 *
 * queriesReducer: handles the queries the user makes to the groupMe API
 *
 */

// constants
import actions from './actionTypes'
import initialState from './initialState'

// add the database variables
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../index';

let queriesReducer = (state = initialState.queries, action) => {
  switch (action.type) {
    case actions.ADD_QUERY:
      // calculate the id to be either 0 or the # of queryIds already in the state
      let id = (state.length === 0) ? 0 : state.map(d => d.queryId)[state.length - 1] + 1;

      // create the new query object
      let newQuery = { 
        queryUserToken: action.payload.userToken,
        queryUserInfo: action.payload.userInfo,
        queryId: id,
        timestamp: new Date(),
        requestAI: null,
        query: action.payload,
        response: {},
        promptLength: 0
      }

      // add the new query to the state
      return [...state, newQuery]

    case actions.UPDATE_RESPONSE_DATA:
      // update the latest state with the response
      let newObject = Object.assign({}, state[state.length - 1]) // the last query
      newObject.response = action.payload[0];
      newObject.requestAI = action.payload[2]; // if this is true, then we request data from Gemini
      newObject.promptLength = action.payload[1];

      // Send newObject to Firestore
      addDoc(collection(db, "queries"), newObject)
        .then(docRef => {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch(error => {
          console.error("Error adding document: ", error);
        });

      // replace the last state with the updated state
      let newState = [...state]
      newState.pop()
      newState.push(newObject)
      return newState
  }

  // if none of the above, return the latest state
  return state
}

export default queriesReducer
