/*
 *
 * userReducer: handles the user state of the app
 *
 */

// constants
import actions from './actionTypes'
import initialState from './initialState'

let userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case actions.SET_USER_GROUPS:
      return Object.assign({}, state, {userGroups: state.userGroups.concat(action.payload)})
    case actions.SET_USER_INFO:
      return Object.assign({}, state, {userInfo: action.payload})
  }
  return state
}

export default userReducer
