import React from 'react'

export default class SelectAGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupsLoaded: false, // State to track if groups are loaded
    };
  }

  componentDidMount() {
    // Simulate fetching groups or check if groups are already available
    if (this.props.groups && this.props.groups.length > 0) {
      this.setState({ groupsLoaded: true });
    }
  }

  componentDidUpdate(prevProps) {
    // Update state when groups are loaded
    if (prevProps.groups !== this.props.groups && this.props.groups.length > 0) {
      this.setState({ groupsLoaded: true });
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    return (
      <div id='select-a-group-container'>
        <div id='select-a-group'>
          <p>Below are all of the GroupMe groups that you are a member of.</p>
        </div>
        <div id='select-a-group'>
          <p>Select one for us to write a story about.</p>
        </div>
        <div id='groups'>
          {this.props.groups.map((group, index) => (
            <div key={index} className='group-item' id='groups'>
              {group}
            </div>
          ))}
        </div>
        {/* Conditionally render the button after groups are loaded */}
        {this.state.groupsLoaded && this.props.groups.length > 10 && ( // min # of groups to show button is 10
          <div id='back-to-top-container'>
            <button onClick={this.scrollToTop} id='back-to-top-button'>
              Scroll back to the top of the list.
            </button>
          </div>
        )}
      </div>
    )
  }
}
