/*
 *
 * React component: LoggedIn - display
 *
 *
 * props: NA
 *
 * states: NA
 *
 */

// APIs
import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// React objects
import Title from './elements/title';
import Footer from './elements/footer';
import Group from './elements/group';
import SelectAGroup from './elements/selectAGroup';
import GroupAnalysis from './elements/groupAnalysis';
import secrets from '../../utils/secrets';

function withNavigation(Component) {
  return function (props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

class LoggedIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true, // Initialize loading state
    };
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const accessToken = url.searchParams.get('access_token');

    if (accessToken) {
      // If the access token is in the URL, set it in Redux and fetch user groups
      this.props.dispatch({ type: 'SET_USER_TOKEN', payload: accessToken });

      // Remove access_token from URL
      url.searchParams.delete('access_token');
      window.history.replaceState({}, document.title, url.pathname);

      // Fetch user groups with the token
      $.ajax({
        url: secrets.apiGroupUrl + accessToken,
        method: 'GET',
        data: { per_page: 100 },
        success: (response) => {
          this.props.dispatch({ type: 'LOGIN' });
          this.props.dispatch({ type: 'SET_USER_GROUPS', payload: response.response });

          // Fetch user info with the token
          $.ajax({
            url: secrets.apiUserUrl + accessToken,
            method: 'GET',
            success: (response) => {
              this.props.dispatch({ type: 'SET_USER_INFO', payload: response.response });
              this.setState({ loading: false }); // Data loaded, stop loading
            },
            error: () => {
              this.props.navigate('/');
              window.alert('Something went wrong. You could not be logged in. Please try again.');
            },
          });
        },
        error: () => {
          this.props.navigate('/');
          window.alert('Something went wrong. You could not be logged in. Please try again.');
        },
      });
    } else if (this.props.userToken) {
      // If userToken exists in Redux, fetch user groups
      $.ajax({
        url: secrets.apiGroupUrl + this.props.userToken,
        method: 'GET',
        data: { per_page: 100 },
        success: (response) => {
          this.props.dispatch({ type: 'LOGIN' });
          this.props.dispatch({ type: 'SET_USER_GROUPS', payload: response.response });

          // Fetch user info with the token
          $.ajax({
            url: secrets.apiUserUrl + this.props.userToken,
            method: 'GET',
            success: (response) => {
              this.props.dispatch({ type: 'SET_USER_INFO', payload: response.response });
              this.setState({ loading: false }); // Data loaded, stop loading
            },
            error: () => {
              this.props.navigate('/');
              window.alert('Something went wrong. You could not be logged in. Please try again.');
            },
          });
        },
        error: () => {
          this.props.navigate('/');
          window.alert('Something went wrong. You could not be logged in. Please try again.');
        },
      });
    } else {
      // If no token in the URL and no token in Redux state, redirect to home
      this.setState({ loading: false }, () => {
        this.props.navigate('/');
      });
    }
  }

  render() {
    const groups = this.props.data.map((d, i) => (
      <Group key={i} data={d} />
    ));

    return (
      <div id='logged-in-display'>
        <Title />
        {!this.props.showAnalysis ? <SelectAGroup groups={groups} /> : <GroupAnalysis groupName={this.props.lastQueryGroupName} />}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showAnalysis: state.view.showAnalysis,
  lastQueryGroupName: state.queries[state.queries.length - 1],
  data: state.user.userGroups,
  userToken: state.token.userToken, // Access the token from Redux
});

export default connect(mapStateToProps)(withNavigation(LoggedIn));
