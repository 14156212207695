// Returns html for the analysis
// Should use D3js here
import $ from 'jquery'
import showGroupStatsSVG from './visualizations/groupStats.js'
import showMessagesSentSVG from './visualizations/messagesSent.js'
import showLikesReceivedSVG from './visualizations/likesReceived.js'
import showLikesPerMessageSVG from './visualizations/likesPerMessage.js'
import showMostLikedMessagesSVG from './visualizations/mostLikedMessages.js'
import showLikesGivenSVG from './visualizations/likesGiven.js'
import displayStory from './app/displays/elements/story.js'

// import the firebase app
import { getVertexAI, getGenerativeModel } from "firebase/vertexai";
import app from './index.js';

// Main function here. This is what grabs the data (from the Python server)
// and initializes the function to ouput the display (using D3.js v4).

// this is where the money is. The function is run when there is
// a successful run of the python script. The response should be
// a JSON object with 'stats' as one of the keys (whose value is
// an extensive object) and some other miscellaneous group findings.
async function analysis(response) {
  // console.log(response);

  // Initialize the Vertex AI service
  const vertexAI = getVertexAI(app);

  // Initialize the generative model with a model that supports your use case
  // Gemini 1.5 models are versatile and can be used with all API capabilities
  const model = getGenerativeModel(vertexAI, { model: "gemini-2.0-flash" });

  // Wrap in an async function so you can use await
  // Provide a prompt that contains text
  const prompt = "Write a short story about the members of this group: " + JSON.stringify(response.messages);
  const lengthOfPrompt = prompt.length;

  // To generate text output, call generateContent with the text input
  let result = await model.generateContent(prompt);
  let aiResponse = result.response;
  let text = aiResponse.text();

  return [text, lengthOfPrompt];


  // let stats = Object.values(response.stats)
  // let allMessages = Object.values(response.allMessages)
  // let likesMatrix = response.likesMatrix

  // Define the function to display the output (so that it can be rendered)
  // let display = () => {
    // This 'resets' the display (for when resizing or other window events)
    // $('#analysis-display-main').html('')

    // new displayStory(text);

    // // Show some group stats
    // showGroupStatsSVG({
    //   'messages': response.messageCount,
    //   'members': response.memberCount
    // })

    // // Messages Sent bar plot
    // showMessagesSentSVG(stats.map(ind => {
    //   return {
    //     'x': ind.Name,
    //     'y': ind.MessagesSent
    //   }
    // }))

    // // Likes Received bar plot
    // showLikesReceivedSVG(stats.map(ind => {
    //   return {
    //     'x': ind.Name,
    //     'y': ind.LikesReceived
    //   }
    // }))

    // // Bubble plot for LpM
    // showLikesPerMessageSVG(stats.map(ind => {
    //   return {
    //     'x': ind.Name,
    //     'y': +((ind['Likes Received'] / ind['Messages Sent']).toFixed(2))
    //   }
    // }))

    // // Most liked messages info
    // showMostLikedMessagesSVG(allMessages.map(ind => {
    //   return {
    //     'rank': ind.level_0,
    //     'text': ind.Message,
    //     'sender': ind.Name,
    //     'time': ind['Time Sent'][1] + '/' + ind['Time Sent'][2] + '/' + ind['Time Sent'][0],
    //     'favorites': ind.Favorites
    //   }
    // }))

    // showLikesGivenSVG(stats.map(ind => {
    //   return {
    //     'x': ind.Name,
    //     'y': ind['Likes Given'],
    //     'z': ind['Likes Received']
    //   }
    // }), likesMatrix)
  // }

  // display()
  // $(window).resize(() => display())
  // $('#hide-button').on('click', () => display())
}

export default analysis
