/*
 *
 * React component: Welcome- display
 *
 *
 * props: NA
 *
 * states: NA
 *
 */

// APIs
import React from 'react'
import Title from './elements/title'
import Footer from './elements/footer'
import secrets from '../../utils/secrets'

export default class Welcome extends React.Component {
  constructor(props) {
    super(props)
    this.handleSignUpClick = this.handleSignUpClick.bind(this)
  }

  handleSignUpClick() {
    // when clicking the 'sign up for groupMe' button
    window.location.href = 'https://web.groupme.com/signup'
  }

  login() {
    window.location.href = 'https://oauth.groupme.com/oauth/authorize?client_id=' + secrets.clientId
  }

  render() {
    let divStyle = {
      // width: '100%',
      float: 'center',
      textAlign: 'center'
    }

    let pStyle = {
      display: 'inline',
      cursor: 'pointer',
    }

    let logoStyle = {
      // width: '50px',
      height: '40px',
      marginBottom: '20px',
      marginLeft: '10px',
      marginRight: '10px',
    }

    return (
      <div id='welcome-display'>
        <Title />
        <div id='welcome-display-content'>
          <br />
          <h2>Our AI will take a look through your GroupMe messages and write a short story about you and your friends.</h2>
          <p>These aren't "real" stories. Just fun AI-generated tales (that can often be wrong).</p>
          <br />
          <div id='welcome-buttons'>
            <div style={divStyle}>
              <button id='login-button' onClick={this.login}>
                <h3>GET STARTED</h3>
              </button>
            </div>
          </div>
          <br />
          {/* <hr id='welcome-page-lines' /> */}
          <br />
          <br />
          <br />
          <br />
          {/* 
          <div style={divStyle}>
            <p>Powered by:</p>
            <br />
            <img src="images/Google_Gemini_logo.svg.png" alt="Google Gemini" style={logoStyle} />
            <br />
            <img src="images/og_image_poundie.png" alt="GroupMe" style={logoStyle} />
          </div>
          */}
          {/* <div>
            <p style={pStyle}>Don't use GroupMe? </p>
            <a style={pStyle} href="https://web.groupme.com/signup" target="_blank">
              <p style={pStyle}>Make a GroupMe account</p>
            </a>
          </div> */}

          {/* <WelcomeSketch /> */}
        </div>
        <Footer />
      </div>
    )
  }
}